#sendButton {
  position: absolute;
  top: 35%;
  right: 15px;
  opacity: 0.5;
}

.container {
  width: 100%;
  min-height: 50%;
  height: 100%;
  /* height: 100vh; */
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 15px;

  max-width: 1800px;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media only screen and (min-width: 1800px) {
  .container {
    margin: auto;
  }
}

.messageArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  overflow-y: inherit;
  overflow-x: hidden;
}

.container:-webkit-scrollbar {
  background: transparent;
  width: 0px;
}
