.configTitle {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  color: black;
}

.configSubtitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: rgba(0, 0, 0, 0.65);
}

.sectionTitle {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.sectionTitle p {
  font-weight: 500;
  line-height: 2;
  color: black;
}

.sectionContent {
}

.sectionContent p {
  padding: 0.75rem 1rem;
  font-weight: 400;
  line-height: 2;
  font-size: 0.875rem;
  line-height: 1.25;
  padding: 0.75rem 1rem;
  color: black;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  border-bottom: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  font-size: 0.8em;
  text-align: left;
  color: black;
  vertical-align: top;
}

table th {
  font-weight: 600;
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.631);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

/*
Different layout for small screens, you can try it if you want

@media screen and (max-width: 576px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
} 
*/
