.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(20px);
  /*
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 75%;*/
}

.header {
  margin-top: 20px;
  font-size: var(--font-size-xlg);
  text-align: center;
  margin-bottom: 25px;
}

.inputForm button {
  background-color: #16101b99;
  border-width: 2px !important;
}
.sendButton {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.sendButtonText {
}

.textInput {
  width: auto;
  background-color: #16101b99;
  border-radius: var(--border-radius-1);
  border: 1px solid var(--blur-border);
  margin-bottom: 15px;
}

.inputForm {
  padding: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.textInput input {
  font-size: var(--font-size-lg);
  width: 100%;
  padding: 8px 15px;
  background: none;
  border: none;
  outline: none;
  color: var(--colour-3);
  resize: none;
}

.includeText {
  font-size: var(--font-size-lg);
  margin-bottom: 6px;
}

.successContainer {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.successText {
  font-size: var(--font-size-lg);
  text-align: center;
  margin-bottom: 20px;
  margin: 0 9px;
}

.warningMessage {
  margin-top: 12px;
  font-size: var(--font-size-m);
  color: rgb(79, 179, 73);
}

.lottie {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
