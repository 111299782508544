.container {
  max-width: var(--max-sidebar-width);
  flex-shrink: 0;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  gap: 16px;
  flex: auto;
  padding: 0px;
  visibility: visible;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
  backdrop-filter: unset;
  -webkit-backdrop-filter: unset;
}

:root {
  --sidebar-padding: 15px;
}

.newSearch {
  padding: var(--sidebar-padding);
  padding-bottom: 0;

  padding-right: 24px;
}

.newSearch Button {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .container {
    position: absolute;
    z-index: 90;
  }

  .hamburgerIcon {
    display: flex !important;
  }

  .newSearch {
    padding-left: 60px;
  }
}

.conversations {
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  padding: 15px;
}

.title {
  color: var(--colour-3);
  font-size: var(--font-size-m);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.hamburgerIcon {
  display: none;
  position: absolute;
  opacity: 0.4;
  z-index: 100000;
  top: -25px;
  left: -25px;
  margin: 10px;
  font-size: 20px;
  cursor: pointer;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background-color: var(--blur-bg);
  border-radius: 10px;
  border: 1px solid var(--blur-border);
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  transition: transform 0.33s;
}

.hamburgerIcon i {
  transition: 0.33s;
}

.hamburgerRotated {
  transform: rotate(360deg);
}

@media (max-width: 1400px) {
  .hamburgerIcon {
    top: 5px;
    left: 0px;
  }
}
