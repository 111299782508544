.container {
  overflow: hidden;
  height: 100%;
}

.gradient:nth-child(1) {
  --top: 0;
  --right: 0;
  --size: 70vw;
  --blur: calc(0.5 * var(--size));
  --opacity: 0.3;
  /*animation: zoom_gradient 6s infinite;*/
}

.gradient {
  position: absolute;
  z-index: -1;
  border-radius: calc(0.5 * var(--size));
  background-color: var(--accent);
  background: radial-gradient(circle at center, var(--accent), var(--accent));
  width: 70vw;
  height: 70vw;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  filter: blur(calc(0.5 * 70vw)) opacity(var(--opacity));
}
