/* General box used for sidebar, chat */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --colour-1: #000000;
  --colour-2: #ccc;
  --colour-3: #e4d4ff;
  --colour-4: #f0f0f0;
  --colour-5: #181818;
  --colour-6: #242424;

  --accent: #8b3dff;
  --blur-bg: #16101b66;
  --blur-border: #84719040;
  --user-input: #ac87bb;
  --color-faded: rgb(190, 190, 190);
  --conversations: #c7a2ff;
}

:root {
  --scale-factor: 1;
  --font-size-sm: calc(13px * var(--scale-factor));
  --font-size-m: calc(14px * var(--scale-factor));
  --font-size-lg: calc(15px * var(--scale-factor));
  --font-size-xlg: calc(18px * var(--scale-factor));
  --font-size-xxlg: calc(19px * var(--scale-factor));
  --font-size-xxxlg: calc(20px * var(--scale-factor));

  --card-base-font-size: var(--font-size-sm);

  --font-1: "Poppins", sans-serif;
  --section-gap: 25px;
  --border-radius-1: 8px;
  --max-card-width: 300px;
  --max-sidebar-width: 250px;
  --card-horizontal-gap: 1.38rem;
  --card-vertical-gap: 1.2rem;
  --max-card-row-width: calc(
    var(--max-card-width) * 3 + 2 * var(--card-horizontal-gap)
  );

  --followup-font-size: var(--font-size-m);
  --message-font-size: var(--font-size-xlg);

  --colour-1: hsl(209 50% 10%);
  --clr-card-bg: hsl(209 50% 5%);
  --colour-3: hsl(209 50% 90%);
  --conversations: hsl(209 50% 80%);
  /*--terminal-text-color: rgb(240, 191, 129);*/
  --terminal-text-color: #a2a2a2;
}

/* Used in Cards  */

h1 {
  font-size: 1.3em;
  letter-spacing: 1px;
}

h2 {
  font-size: 1.23em;
  letter-spacing: 1px;
}

h3 {
  font-size: 1.154em;
  letter-spacing: 1px;
}

h4 {
  font-size: 1.076em;
}

@media (min-width: 576px) {
  :root {
    --scale-factor: 1;
  }
}

@media (min-width: 768px) {
  :root {
    --scale-factor: 1;
  }
}

@media (min-width: 992px) {
  :root {
    --scale-factor: 1;
  }
}

@media (min-width: 1200px) {
  :root {
    --scale-factor: 1;
  }
}

@media (min-width: 1400px) {
  :root {
    --scale-factor: 1;
  }
}

* {
  font-family: var(--font-1);
  margin: 0;
  box-sizing: border-box;
  position: relative;
  letter-spacing: 0.7px;
}

/* Used in Cards */

p,
span {
  /* For testing */
  /* background-color: rgba(255, 0, 0, 0.529); */
}

.box {
  background-color: #16101b66;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius-1);
  border: 1px solid var(--blur-border);
}

body {
  scroll-behavior: smooth;
  background: var(--colour-1);
  color: var(--colour-3);
  scrollbar-gutter: stable both-edges;
  -webkit-tap-highlight-color: transparent;

  /*font-family: "Noto Sans", sans-serif;
  font-family: "Montserrat", sans-serif;*/
}

hr {
  display: block;
  margin-bottom: 0em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 0.5px;
  border-color: black;
}

.clickable {
  cursor: pointer;
  color: var(--accent);
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.1);
  transition: background 0.5s;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background: rgba(136, 136, 136, 0.8);
}

a:-webkit-any-link {
  color: var(--accent);
  text-decoration: none;
}
