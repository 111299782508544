.container {
  display: flex;
  align-items: center;
}

.container a {
  display: inline-block;
  margin-right: 5px;
  margin-top: 3px;
}
