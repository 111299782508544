.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  width: 60rem;
  height: 80vh;
  max-width: calc(100vw - 2rem);
  overflow-y: auto;
  background-color: transparent;
}

/* Dont move icon on scroll */
.closeIcon {
  position: absolute;
  top: 0;
  right: 40px;
  margin: 1rem;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
}
