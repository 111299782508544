.container {
  display: flex;
  margin-bottom: 1em;
}

.rightSide {
  display: flex;
  flex-direction: column;
}

.headerImage {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 10px;
}
