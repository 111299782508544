:root {
  --processing-indicator-opacity: 0.9; /* maxium opacity of the text when it fades in */
}

.container {
  opacity: var(--processing-indicator-opacity);
}
.text {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.text.fadeIn {
  opacity: var(--processing-indicator-opacity);
}

.text.fadeOut {
  opacity: 0;
}
