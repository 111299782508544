.message {
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  padding: var(--section-gap);
  padding: 0px;
  padding-top: 20px;
  letter-spacing: 1px;
  width: 100%;
}

@media (max-width: 992px) {
}

.userMessage {
  justify-content: end;
  text-align: left;
  margin-left: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.userAvatar {
  margin-bottom: 0px;
}

.textMessage {
  width: 75%;
  font-size: var(--message-font-size);
}

.cardMessageHeader {
  margin-bottom: 8px;
  margin-top: 3px;
  margin-right: 5px;
  color: var(--color-faded);
}

.cardMessageBody {
  display: flex;
  flex-direction: column;
  gap: var(--card-vertical-gap);
}

.cardMessage {
  max-width: var(--max-card-row-width);
  min-width: 0;
}

.estimatedResults {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xxlg);
}

.estimatedResults p {
  margin-left: auto;
}

.cardRow {
  display: flex;
  gap: var(--card-horizontal-gap);

  justify-content: flex-start;
}

.avatarContainer {
  max-width: 48px;
  max-height: 48px;
  flex-shrink: 0;
  margin-right: 25px;
  margin-left: 25px;
}

.avatarContainer:after {
  content: "63";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 60%;
  width: 60%;
  background: var(--colour-3);
  filter: blur(10px) opacity(0.1);
  z-index: 60;
}

.avatarContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  outline: 1px solid var(--blur-border);
}

.textMessage code {
  margin-top: 5px;
  display: block;
  background-color: #100101;
  color: white;
  font-family: sans-serif;
  padding: 20px 20px;
  border-radius: 5px;
}

.textMessage span,
.textMessage p {
  letter-spacing: 1px;
}

.actionArea {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: var(--max-card-row-width);
}

.viewMoreAndFeedback {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 15px;
  justify-content: space-between;
}

.viewMoreAndFeedback button {
  font-size: 13px;
}

.viewMore {
}

.feedback {
  display: flex;
  grid-gap: 15px;
  justify-content: flex-end;
}

.followupContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.followup button {
  width: 100%;
  font-size: 15px;
}

.showMoreAndFeedback button {
  font-size: 13px;
  opacity: 0.5;
}

@media only screen and (max-width: 1110px) {
  .textMessage {
    width: 95%;
  }
  .message {
    padding: 8px;
  }
  .avatarContainer {
    margin-right: 10px;
    margin-left: 10px;
    max-width: 22px;
    max-height: 22px;
  }
}

@media only screen and (max-width: 750px) {
  .avatarContainer {
    margin-left: 0px;
  }
}
