#sendButton {
  position: absolute;
  top: 50%; /* Position the top at 50% of the container */
  transform: translate(-50%, -45%); /* Adjust to center the element */
  right: 10px;
  color: var(--terminal-text-color);
  cursor: pointer;
}

/* When there is no text fade the button */
.inactive {
  opacity: 0.5;
  cursor: default !important;
}

#dollarSign {
  position: absolute;
  left: 10px;
  top: 15px;
  font-size: var(--message-font-size);
  color: var(--terminal-text-color);
  user-select: none;
}

.inputBox {
  display: flex;
  /*align-items: center;*/
  /*padding-right: 15px;*/
  z-index: 50;
  cursor: pointer;
  position: absolute;
  backdrop-filter: blur(10px);
  bottom: 0px;
  width: 100%;
  border-bottom-left-radius: var(--border-radius-1);
  border-bottom-right-radius: var(--border-radius-1);
  background-color: rgb(33, 33, 33) !important;
}

.inputBox textarea {
  font-size: var(--message-font-size);
  width: 100%;
  padding: 15px 50px 15px 30px;
  caret-shape: block;

  background: none;
  border: none;
  outline: none;
  color: var(--terminal-text-color);
  resize: none;
  letter-spacing: 0.5px;
}
