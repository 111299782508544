.input {
  background: transparent;
  border: none;
  color: var(--colour-3);
  width: 100%;
}

.input:focus {
  outline: none;
}

.editable {
  cursor: text;
  border: 1px solid var(--user-input);
}

.readOnly {
  cursor: pointer;
}
