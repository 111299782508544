.commonButton {
}

.commonContent {
}

.button {
  padding: 8px 12px;
  display: flex;
  gap: 18px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: 1px solid rgba(199, 162, 255, 0.5);
  /*  border: 1px dashed --var(conversations) */
  border-radius: var(--border-radius-1);

  color: var(--colour-3);
  font-size: var(--font-size-m);
}

.button.selected,
.button:hover {
  border-style: solid;
  border: 1px solid rgba(199, 162, 255, 1);
}

/* ================ Variants ================== */
/* dashed */

.dashed {
  border: 1px dashed var(--conversations);
}
.dashed:hover {
  border: 1px solid var(--conversations);
}

.dashed.selected {
  border: 1px solid var(--conversations);
}

/* Clear */

.clear {
  border: 1px solid transparent;
}

.clear:hover {
  border: 1px dashed;
}

.clear.selected {
  border: 1px solid var(--conversations);
}
