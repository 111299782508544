/* Shared css module for all cards */

.card {
  background-color: #100101;
  padding: 15px;

  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

  font-size: var(--card-base-font-size);
  letter-spacing: 0.5px;
  line-height: 1.5;
  min-height: 320px;
  min-width: 300px;
  border: 2px solid black;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  flex: 0 1 var(--max-card-width); /* Change this for max card size */
}

.cardRow {
  display: flex;
  gap: var(--card-horizontal-gap);

  justify-content: flex-start;
}

.profileHeader {
  display: flex;
  margin-bottom: 5px;
}

@media only screen and (max-width: 750px) {
  .card {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
}

.card.selectableCardard {
  border: 2px solid #c7a2ff80;
}

.card.selectableCardard:hover {
  border-color: #c7a2ff;
  cursor: pointer;
}

.card ul {
  padding-left: 15px;
}
