.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 23px;
  width: 23px;
  background-color: #07050899;
  border-radius: 50%;
  border: solid rgba(255, 255, 255, 0.146);
  border-width: 1px;
  box-sizing: border-box;
}

.container:hover input ~ .checkmark {
}

.container input:checked ~ .checkmark {
  background-color: var(--accent);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}
