.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 10%;
}

.container p {
  margin-top: 20px;
}
