.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.progressBar {
  width: 95%;
  height: 15px;
  accent-color: rgb(77, 77, 136);
  cursor: pointer;
}
