.tooltip .tooltiptext {
  visibility: hidden;
  width: 105%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
}

.tooltip p {
  font-size: var(--font-size-sm);
  padding-top: 10x;
  padding-bottom: 10px;
}

/*
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
*/
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.tooltip button {
  border-color: transparent;
  font-size: var(--font-size-sm);
  padding: 5px;
  text-align: right;
  justify-content: center;
  color: var(--accent);
}
