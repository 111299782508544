.container {
  margin-top: 20px;
}

.title {
  font-size: 1.25em;
  margin-top: 8px;
  margin-bottom: 5px;
}

.outerLine {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid rgb(240, 0, 0);
  text-align: center;
}

.insideLine {
  font-size: 10px;
  padding: 0 10px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.headerLine {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.569);
}

.headerText {
  flex: 1;
  text-align: center;
  padding: 0 10px;
}
