.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fullName {
  display: flex;
  align-items: center;
  margin-bottom: 3;
  gap: 0.7em;
}

.company {
  display: flex;
  margin-bottom: 0.8em;
  align-items: center;
}

.educationDetails {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin-bottom: 0.5em;
}

.companyDetails {
}

.companyDetails p {
}

.company img {
  width: 35px;
  height: 35px;
  margin-right: 8px;
}

.bottom {
  margin-top: 25px;
  display: flex;
  height: 100%;
  /* Because bottom buttons have transparent borders, */
  /* It looks like the bottom of the card has extra padding so we adjust it here */
  margin-bottom: -5px;
  align-items: flex-end;
}

.profileContact {
  margin-top: 10px;
  text-align: center;
  color: var(--accent);
  display: flex;
  justify-content: space-between;
}

.shortSummary {
  margin-top: 10px;
}

.bullet {
  padding: 3px;
}

.skills {
  text-align: center;
}

.profileContact button {
  border-color: transparent;
  font-size: var(--card-base-font-size);
  padding: 5px;
  justify-content: center;
  color: var(--accent);
}

.profileContact a {
  padding: 2px;
}
