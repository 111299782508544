:root {
  --chatwindow-padding: 25px;
}
.chatPage {
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  padding: var(--chatwindow-padding);
}

@media (max-width: 1110px) {
  :root {
    --chatwindow-padding: 0px;
  }
}

.chatContainer {
  display: flex;
  gap: 25px;
  height: 100%;
}

.authContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
