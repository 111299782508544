@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  content: "";
  box-sizing: border-box;
  margin-top: 20px;
  left: 45%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--conversations);
  border-top-color: white;
  animation: spinner 2s linear infinite;
}
