.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.profileControl {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 32px;
  padding-bottom: 15px;
}
