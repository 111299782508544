.container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  height: 80vh;
}

.container h1,
.container p {
  color: black;
}
